export const GET_LIST_PRODUCT_ACTIVATION_CODE = "GET_LIST_PRODUCT_ACTIVATION_CODE";
export const GET_LIST_PRODUCT_ACTIVATION_CODE_SUCCESS = "GET_LIST_PRODUCT_ACTIVATION_CODE_SUCCESS";
export const GET_LIST_PRODUCT_ACTIVATION_CODE_ERROR = "GET_LIST_PRODUCT_ACTIVATION_CODE_ERROR";
export const CLEAR_LIST_PRODUCT_ACTIVATION_CODE = 'CLEAR_LIST_PRODUCT_ACTIVATION_CODE';

export const CREATE_PRODUCT_ACTIVATION_CODE = "CREATE_PRODUCT_ACTIVATION_CODE";
export const CREATE_PRODUCT_ACTIVATION_CODE_SUCCESS = "CREATE_PRODUCT_ACTIVATION_CODE_SUCCESS";
export const CREATE_PRODUCT_ACTIVATION_CODE_CLEAR = "CREATE_PRODUCT_ACTIVATION_CODE_CLEAR";
export const CREATE_PRODUCT_ACTIVATION_CODE_ERROR = "CREATE_PRODUCT_ACTIVATION_CODE_ERROR";

export const CREATE_BULK_PRODUCT_ACTIVATION_CODE = "CREATE_BULK_PRODUCT_ACTIVATION_CODE";
export const CREATE_BULK_PRODUCT_ACTIVATION_CODE_SUCCESS = "CREATE_BULK_PRODUCT_ACTIVATION_CODE_SUCCESS";
export const CREATE_BULK_PRODUCT_ACTIVATION_CODE_CLEAR = "CREATE_BULK_PRODUCT_ACTIVATION_CODE_CLEAR";
export const CREATE_BULK_PRODUCT_ACTIVATION_CODE_ERROR = "CREATE_BULK_PRODUCT_ACTIVATION_CODE_ERROR";

export const UPDATE_PRODUCT_ACTIVATION_CODE = "UPDATE_PRODUCT_ACTIVATION_CODE";
export const UPDATE_PRODUCT_ACTIVATION_CODE_SUCCESS = "UPDATE_PRODUCT_ACTIVATION_CODE_SUCCESS";
export const UPDATE_PRODUCT_ACTIVATION_CODE_CLEAR = "UPDATE_PRODUCT_ACTIVATION_CODE_CLEAR";
export const UPDATE_PRODUCT_ACTIVATION_CODE_ERROR = "UPDATE_PRODUCT_ACTIVATION_CODE_ERROR";

export const REMOVE_PRODUCT_ACTIVATION_CODE = "REMOVE_PRODUCT_ACTIVATION_CODE";
export const REMOVE_PRODUCT_ACTIVATION_CODE_SUCCESS = "REMOVE_PRODUCT_ACTIVATION_CODE_SUCCESS";
export const REMOVE_PRODUCT_ACTIVATION_CODE_CLEAR = "REMOVE_PRODUCT_ACTIVATION_CODE_CLEAR";
export const REMOVE_PRODUCT_ACTIVATION_CODE_ERROR = "REMOVE_PRODUCT_ACTIVATION_CODE_ERROR";

export const EXPORT_CSV_PRODUCT_ACTIVATION_CODE = "EXPORT_CSV_PRODUCT_ACTIVATION_CODE";
export const EXPORT_CSV_PRODUCT_ACTIVATION_CODE_SUCCESS = "EXPORT_CSV_PRODUCT_ACTIVATION_CODE_SUCCESS";
export const EXPORT_CSV_PRODUCT_ACTIVATION_CODE_CLEAR = "EXPORT_CSV_PRODUCT_ACTIVATION_CODE_CLEAR";
export const EXPORT_CSV_PRODUCT_ACTIVATION_CODE_ERROR = "EXPORT_CSV_PRODUCT_ACTIVATION_CODE_ERROR";