import React, { Fragment, useCallback, useEffect, useMemo, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import {
  clearBundleCreated,
  clearBundleRemoved,
  clearBundleUpdated,
  getListBundles,
  changeBundleStatusClear,
  clearListBundles,
} from "../../../store/dashboard/Bundles/actions";

import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
} from "../../../constants/pagination";
import Placeholder from "../../../components/Common/Placeholder";
import InfiniteScroll from "../../../components/Common/InfinitScroll";
import useSnackbarState from "../../../hooks/useSnackbarState";
// import VideoTableRow from "./components/VideoTableRow";
import withUploadingError from "../../../HOCs/withUploadingError";

import styles from "../dashboard.module.scss";
import SEO from "../../../components/Common/SEO";
import productActivationCodesStructure from './tableStructure';
import { PRODUCT_ACTIVATION_CODES_LIST_LIMIT } from '../../../constants/paginationConstants';
import useTable from '../../../hooks/useTable';
import Table from "../../../components/Table";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import { clearBulkProductActivationCodeCreated, clearListProductActivationCodes, clearProductActivationCodeCreated, clearProductActivationCodeExportCsv, clearProductActivationCodeRemoved, clearProductActivationCodeUpdated, exportCsvProductActivationCode, getListProductActivationCodes, removeProductActivationCode } from '../../../store/dashboard/ProductActivationCodes/actions';
import csvIconSvg from "../../../assets/images/ic-csv-file.svg";
import fileDownload from 'js-file-download';

const ProductActivationCodes = ({
  clearProductActivationCodeCreated,
  productActivationCodes,
  getListProductActivationCodes,
  clearProductActivationCodeUpdated,
  clearListProductActivationCodes,
  clearProductActivationCodeRemoved,
  removeProductActivationCode,
  clearBulkProductActivationCodeCreated,
  exportCsvProductActivationCode,
  clearProductActivationCodeExportCsv,
}) => {
  const { createdBulk, updated, removed, changedStatus, csvData } = productActivationCodes;
  const scrollRef = useRef(null);
  // const fetchList = (nextPagination = {}) => {
  //   const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = nextPagination;
  //   getListVideoLibrary({ model: { limit, offset } });
  // };

  // console.log("rendering ProductActivationCodes");

  const { data, pagination, loading, handleTableChange, query, getList } = useTable(
    productActivationCodes,
    getListProductActivationCodes,
    PRODUCT_ACTIVATION_CODES_LIST_LIMIT
  );

  useEffect(() => {
    // perform effect if entity appears
    if (csvData) {
      fileDownload(csvData, 'product-activation-codes.csv');
      clearProductActivationCodeExportCsv();
    }

  }, [csvData]);

  useEffect(() => {
    // perform effect if entity appears
    if (removed) {
      getList();
      clearProductActivationCodeRemoved();
    }
  }, [removed]);

  const [isUpdated] = useSnackbarState(updated, clearProductActivationCodeUpdated);
  const [isBulkCreated] = useSnackbarState(createdBulk, clearBulkProductActivationCodeCreated);

  // const loadMore = useCallback((offset) => fetchList({ offset }), []);

  // useEffect(() => {
  //   // perform effect if entity appears
  //   if (removed) {
  //     fetchList({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET });
  //     // clearVideoLibraryRemoved();
  //   }
  // }, [removed]);

  useEffect(() => {
    // Fetch data
    // fetchList();

    return () => {
      // clearVideoLibraryRemoved();
      clearProductActivationCodeRemoved();
      clearListProductActivationCodes();
      clearBulkProductActivationCodeCreated();
      clearProductActivationCodeExportCsv();
    };
  }, []);

  // useEffect(() => {
  //   if (!loading && !!changedStatus) {
  //     changeVideoLibraryStatusClear();
  //   }
  // }, [changedStatus, loading]);

  return (
    <ScrollbarVertical>
      <SEO title="Product Activation Codes" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Product Activation Codes</h4>
              </div>
            </Col>
          </Row>
          {isBulkCreated && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  Bulk product activation codes has been successfully created
                </Alert>
              </Col>
            </Row>
          )}
          {isUpdated && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  The product activation code has been successfully updated
                </Alert>
              </Col>
            </Row>
          )}
          <Card className={styles.cardFullsize}>
            <CardBody>
              <CardTitle className="d-flex flex-row justify-content-between h-auto">
                <div className={styles.header}>
                  <div className='d-flex flex-column'>
                    <p><strong>Resources</strong></p>
                    <a href='https://crossfader-dev-files.s3.eu-central-1.amazonaws.com/files/admins/assets/csv/productActivationCodesExample.csv' className='d-flex mb-2' target='_black' rel='noopener noreferrer'>
                      <span className="logo-sm mr-2">
                        <img src={csvIconSvg} alt="" height="22" />
                      </span>
                      <span>Coupon upload template</span>
                    </a>
                    <a href='https://crossfader-dev-files.s3.eu-central-1.amazonaws.com/files/admins/assets/csv/prod_live_course_list.csv' className='d-flex' target='_black' rel='noopener noreferrer'>
                      <span className="logo-sm mr-2">
                        <img src={csvIconSvg} alt="" height="22" />
                      </span>
                      <span>Course List with ID's</span>
                    </a>
                  </div>
                
                </div>
                <div className='d-flex flex-column'>
                  <Link to={`/product-activation-codes/create-bulk`} className='mb-2'>
                    <Button color="primary">Upload Product Activation Codes (CSV)</Button>
                  </Link>
                  <Button disabled={loading} onClick={exportCsvProductActivationCode} color="primary">Export (CSV)</Button>
                </div>
                
              </CardTitle>
            
              <CardText tag={"div"}>
              <Row>
                  <Col xs="12">
                    <Table
                      isRowClick={false}
                      needSearch
                      dataStructure={productActivationCodesStructure.bind(null, removeProductActivationCode )}
                      data={data}
                      handleTableChange={handleTableChange}
                      totalCount={pagination.totalCount || 0}
                      limit={PRODUCT_ACTIVATION_CODES_LIST_LIMIT}
                      offset={query.offset || 0}
                      loading={loading}
                      rowClickFunc={()=>{}}
                      placeholderText="no production activation code created"
                    />
                  </Col>
                </Row>
                {/* <Row className="h-100 scrollbar-wrapper">
                  {!loading && !data.length && (
                    <Placeholder text="no videos created" />
                  )}
                  <InfiniteScroll
                    className="w-100 h-100"
                    ref={scrollRef}
                    disabled={data?.length === 0 || loading}
                    offset={pagination.nextOffset}
                    nextOffset={pagination.nextOffset}
                    totalCount={pagination.totalCount}
                    loadMore={loadMore}
                  >
                    {!!data && !!data.length > 0 && (
                      <div className="table-responsive col-12">
                        <Table className="table mt-0 mb-0 border-bottom">
                          <thead className="thead-light">
                            <tr>
                              <th>Video Id</th>
                              <th>Video Title</th>
                              <th>Asset Id</th>
                              <th>Video Tags</th>
                              <th className="w-220px text-right pr-5">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((video, index) => (
                              <Fragment key={"table-item-" + index}>
                                <VideoTableRow video={video} />
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {loading &&
                      !!data.length &&
                      data.length < pagination.totalCount && (
                        <Col xs={12}>
                          <div className="text-center my-3">
                            <div className="text-success">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />{" "}
                              Load more
                            </div>
                          </div>
                        </Col>
                      )}
                  </InfiniteScroll>
                </Row> */}
              </CardText>
              </CardBody>
              </Card>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = (state) => {
  const { ProductActivationCodes } = state;

  return { productActivationCodes: ProductActivationCodes };
};

// Custom comparison function to prevent re-renders
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.productActivationCodes.data === nextProps.productActivationCodes.data &&
    prevProps.productActivationCodes.loading === nextProps.productActivationCodes.loading &&
    prevProps.productActivationCodes.createdBulk === nextProps.productActivationCodes.createdBulk &&
    prevProps.productActivationCodes.updated === nextProps.productActivationCodes.updated &&
    prevProps.productActivationCodes.removed === nextProps.productActivationCodes.removed
  );
};

export default withUploadingError(
  connect(mapStateToProps, {
    clearProductActivationCodeCreated,
    clearProductActivationCodeUpdated,
    getListProductActivationCodes,
    clearListProductActivationCodes,
    clearProductActivationCodeRemoved,
    removeProductActivationCode,
    clearBulkProductActivationCodeCreated,
    exportCsvProductActivationCode,
    clearProductActivationCodeExportCsv,
    
  })(React.memo(ProductActivationCodes, areEqual))
);
