import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";

class ProductActivationCodeService extends Http {
  BASE_URL = "/admins/product-activation-codes";

  getList(params) {
    // const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}`, {
      params
    });
  }

  createBulk(model) {
    return this.post(`${this.BASE_URL}/bulk`, model);
  }

  remove(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }

  exportCsv() {
    return this.post(`${this.BASE_URL}/export-csv`);
  }
}

export default new ProductActivationCodeService();