import { CLEAR_LIST_PRODUCT_ACTIVATION_CODE, CREATE_BULK_PRODUCT_ACTIVATION_CODE, CREATE_BULK_PRODUCT_ACTIVATION_CODE_CLEAR, CREATE_BULK_PRODUCT_ACTIVATION_CODE_ERROR, CREATE_BULK_PRODUCT_ACTIVATION_CODE_SUCCESS, CREATE_PRODUCT_ACTIVATION_CODE_CLEAR, EXPORT_CSV_PRODUCT_ACTIVATION_CODE, EXPORT_CSV_PRODUCT_ACTIVATION_CODE_CLEAR, EXPORT_CSV_PRODUCT_ACTIVATION_CODE_ERROR, EXPORT_CSV_PRODUCT_ACTIVATION_CODE_SUCCESS, GET_LIST_PRODUCT_ACTIVATION_CODE, GET_LIST_PRODUCT_ACTIVATION_CODE_ERROR, GET_LIST_PRODUCT_ACTIVATION_CODE_SUCCESS, REMOVE_PRODUCT_ACTIVATION_CODE, REMOVE_PRODUCT_ACTIVATION_CODE_CLEAR, REMOVE_PRODUCT_ACTIVATION_CODE_ERROR, REMOVE_PRODUCT_ACTIVATION_CODE_SUCCESS, UPDATE_PRODUCT_ACTIVATION_CODE_CLEAR } from './actionTypes';

export const getListProductActivationCodes = (payload) => {
  return {
    payload,
    type: GET_LIST_PRODUCT_ACTIVATION_CODE
  };
};
export const getListProductActivationCodesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_PRODUCT_ACTIVATION_CODE_SUCCESS,
  };
};
export const getListProductActivationCodesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_PRODUCT_ACTIVATION_CODE_ERROR,
  };
};
export const clearListProductActivationCodes = () => ({
  type: CLEAR_LIST_PRODUCT_ACTIVATION_CODE,
});


export const clearProductActivationCodeCreated = () => {
  return {
    type: CREATE_PRODUCT_ACTIVATION_CODE_CLEAR,
  };
};

export const createBulkProductActivationCode = (data, history) => {
  return {
    payload: { data, history },
    type: CREATE_BULK_PRODUCT_ACTIVATION_CODE,
  };
};

export const createBulkProductActivationCodeSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_BULK_PRODUCT_ACTIVATION_CODE_SUCCESS,
  };
};

export const createBulkProductActivationCodeError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_BULK_PRODUCT_ACTIVATION_CODE_ERROR,
  };
};

export const clearBulkProductActivationCodeCreated = () => {
  return {
    type: CREATE_BULK_PRODUCT_ACTIVATION_CODE_CLEAR,
  };
};

export const clearProductActivationCodeUpdated = () => {
  return {
    type: UPDATE_PRODUCT_ACTIVATION_CODE_CLEAR,
  };
};

export const removeProductActivationCode = (data, history) => {
  return {
    payload: { data, history },
    type: REMOVE_PRODUCT_ACTIVATION_CODE,
  };
};

export const removeProductActivationCodeSuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_PRODUCT_ACTIVATION_CODE_SUCCESS,
  };
};

export const removeProductActivationCodeError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_PRODUCT_ACTIVATION_CODE_ERROR,
  };
};

export const clearProductActivationCodeRemoved = () => {
  return {
    type: REMOVE_PRODUCT_ACTIVATION_CODE_CLEAR,
  };
};

export const exportCsvProductActivationCode = () => {
  return {
    type: EXPORT_CSV_PRODUCT_ACTIVATION_CODE,
  };
};

export const exportCsvProductActivationCodeSuccess = (data) => {
  return {
    payload: data,
    type: EXPORT_CSV_PRODUCT_ACTIVATION_CODE_SUCCESS,
  };
};

export const exportCsvProductActivationCodeError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: EXPORT_CSV_PRODUCT_ACTIVATION_CODE_ERROR,
  };
};

export const clearProductActivationCodeExportCsv = () => {
  return {
    type: EXPORT_CSV_PRODUCT_ACTIVATION_CODE_CLEAR,
  };
};

